@import "~ng-uikit-pro-standard/assets/scss/core/colors";
@import "~ng-uikit-pro-standard/assets/scss/core/variables";
@import "~ng-uikit-pro-standard/assets/scss/core/variables-pro";

@import "~ng-uikit-pro-standard/assets/scss/sections-pro/_contacts-pro.scss";
html,
body,
header,
#intro {
  height: 100%;
}

#intro {
  background: url("assets/examples/photo1.jpg")no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.top-nav-collapse {
  background-color: #24355C;
}
@media (max-width: 768px) {
  .navbar:not(.top-nav-collapse) {
    background-color: #24355C;
  }
}
@media (min-width: 800px) and (max-width: 850px) {
  .navbar:not(.top-nav-collapse) {
    background-color: #24355C;
  }

}
